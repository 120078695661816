import DefaultLayout from '@/layouts/Default';
import SocialServiceIndex from '@/views/SocialService/Index';
import SocialServiceDetail from '@/views/SocialService/Detail';
import SocialServiceNew from '@/views/SocialService/New';

export default {
    path: '/soz',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'social_service',
            component: SocialServiceIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'social_service_new',
            component: SocialServiceNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'social_service_detail',
            component: SocialServiceDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
