var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"pa-3",attrs:{"headers":_vm.headers,"items":_vm.objects,"search":_vm.search,"show-select":_vm.selection,"footer-props":_vm.defaultFooterProps,"items-per-page":_vm.getPrefenceValue('office_labor.itemsPerPage', 25),"page":_vm.currentPage,"group-by":"canton.name"},on:{"update:items-per-page":function (val) { return _vm.setPrefenceValue('office_labor.itemsPerPage', val); },"update:page":function (page) { return _vm.currentPage = page; }},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"5"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")]),_vm._v(" "+_vm._s(items[0].canton.name)+" ("+_vm._s(items[0].canton.code)+") ")],1)]}},{key:"item.office_name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"link":"","text":"","to":{ name: _vm.detailRoute, params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.office_name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.copyText(item.email)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.copy'))}})]):_vm._e(),_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":("mailto:" + (item.email))}},on),[_c('v-icon',[_vm._v(" mdi-email-outline ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('office.send_email'))}})]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","to":{ name: _vm.detailRoute, params: { id: item.id }}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.edit'))}})]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.initiateDeletion(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('general.delete'))}})],1)],1)],1)],1)]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('ConfirmationDialog',{attrs:{"confirm":_vm.deleteObject,"cancel":function () { return (_vm.deleteDialog = false); },"loading":_vm.deleting,"dialog":_vm.deleteDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }