<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.name"
                        :label="$t('weekname.name')"
                        :error-messages="formError(errors, 'name')"
                    />
                </v-col>
                <v-col cols="4">
                    <v-select
                        :items="weeks"
                        v-model="data.week_no"
                        :label="$t('weekname.week')"
                        :error-messages="formError(errors, 'week_no')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col md="4">
                    <v-select
                        :items="courseTypes"
                        v-model="data.course_type"
                        :label="$t('course.type')"
                        :error-messages="formError(errors, 'course_type')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col md="4">
                    <v-select
                        :items="courseLanguages"
                        v-model="data.language"
                        :label="$t('course.language')"
                        :error-messages="formError(errors, 'language')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'OfficeInformation',
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            courseTypes: [
                {
                    value: 'OL',
                    text: `Kursart ${this.$t('course.types.OL')}`,
                },
                {
                    value: 'PS_SOCIAL',
                    text: `Kursart ${this.$t('course.types.PS_SOCIAL')}`,
                },
                {
                    value: 'PS_STORY',
                    text: `Kursart ${this.$t('course.types.PS_STORY')}`,
                },
                {
                    value: 'PS_VIDEO',
                    text: `Kursart ${this.$t('course.types.PS_VIDEO')}`,
                },
                {
                    value: 'APPLICATION_AGENCY',
                    text: `Kursart ${this.$t('course.types.APPLICATION_AGENCY')}`,
                },
            ],
            courseLanguages: [
                {
                    value: 'de',
                    text: 'Deutsch',
                },
                {
                    value: 'en',
                    text: 'Englisch',
                },
            ],
            weeks: [
                {
                    value: 1,
                    text: `${this.$t('calendar.week')} 1`,
                },
                {
                    value: 2,
                    text: `${this.$t('calendar.week')} 2`,
                },
                {
                    value: 3,
                    text: `${this.$t('calendar.week')} 3`,
                },
                {
                    value: 4,
                    text: `${this.$t('calendar.week')} 4`,
                },
                {
                    value: 5,
                    text: `${this.$t('calendar.week')} 5`,
                },
                {
                    value: 6,
                    text: `${this.$t('calendar.week')} 6`,
                },
                {
                    value: 7,
                    text: `${this.$t('calendar.week')} 7`,
                },
                {
                    value: 8,
                    text: `${this.$t('calendar.week')} 8`,
                },
                {
                    value: 9,
                    text: `${this.$t('calendar.week')} 9`,
                },
                {
                    value: 10,
                    text: `${this.$t('calendar.week')} 10`,
                },
                {
                    value: 11,
                    text: `${this.$t('calendar.week')} 11`,
                },
            ],
        }
    },
};
</script>
