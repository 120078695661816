<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="office_labor"
        />
        <v-row>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <OfficeLaborForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import OfficeForm from '@/components/office/Form/OfficeForm.vue';
import OfficeLaborProxy from '@/proxies/OfficeLaborProxy';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'OfficeLaborDetail',
    components: {
        PageHeader,
        OfficeLaborForm: OfficeForm,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            loading: false,
            data: {},
            rawData: {},
        };
    },
    computed: {
        pageTitle() {
            return this.rawData.office_name;
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.office_labor.title'),
                    exact: true,
                    to: { name: 'office_labor' },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            new OfficeLaborProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = data.data;
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });

        },
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            const formData = {
                office_labor: {
                    officeName: this.data.office_name,
                    address: this.data.address,
                    zip: this.data.zip,
                    city: this.data.city,
                    officePhone: this.data.office_phone,
                    fax: this.data.fax,
                    memo: this.data.memo,
                    firstname: this.data.firstname,
                    lastname: this.data.lastname,
                    phone: this.data.phone,
                    email: this.data.email,
                    canton: this.data.canton.code,
                },
            };
            new OfficeLaborProxy().update(this.$route.params.id, formData).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('office_labor.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
