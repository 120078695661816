<template>
    <v-row>
        <v-col cols="12">
            <v-select
                v-model="data.salutation"
                :items="salutations"
                :label="$t('participant.salutation')"
                :error-messages="formError(formErrorsProp, 'salutation')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.firstname"
                :label="$t('participant.firstname')"
                :error-messages="formError(formErrorsProp, 'firstname')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.lastname"
                :label="$t('participant.lastname')"
                :error-messages="formError(formErrorsProp, 'lastname')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-textarea
                type="text"
                v-model="data.address"
                :label="$t('participant.address')"
                :error-messages="formError(formErrorsProp, 'address')"
                :hint="$t('general.required')"
                rows="2"
                persistent-hint
            />
        </v-col>
        <v-col md="6">
            <v-text-field
                type="text"
                v-model="data.zip"
                :label="$t('participant.zip')"
                :error-messages="formError(formErrorsProp, 'zip')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col md="6">
            <v-text-field
                type="text"
                v-model="data.city"
                :label="$t('participant.city')"
                :error-messages="formError(formErrorsProp, 'city')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-select
                v-model="data.canton.code"
                :items="cantons"
                :label="$t('participant.canton')"
                :error-messages="formError(formErrorsProp, 'canton')"
                clearable
                @click:clear="$nextTick(() => data.canton = null)"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.phone"
                :label="$t('participant.phone')"
                :error-messages="formError(formErrorsProp, 'phone')"
                :hint="$t('general.phone_or_mobile_required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.mobile"
                :label="$t('participant.mobile')"
                :error-messages="formError(formErrorsProp, 'mobile')"
                :hint="$t('general.phone_or_mobile_required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="email"
                v-model="data.email"
                :label="$t('participant.email')"
                :error-messages="formError(formErrorsProp, 'email')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <BirthdatePicker
                v-model="data.birthdate"
                :label="$t('participant.birthdate')"
                :errors="formErrorsProp"
                error-key="birthdate"
                required
            />
        </v-col>
        <v-col cols="12">
            <v-select
                v-model="data.civil_status"
                :items="civilStatuses"
                :label="$t('participant.civil_status')"
                :error-messages="formError(formErrorsProp, 'civilStatus')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-autocomplete
                v-model="data.nationality"
                :items="nationalities"
                :label="$t('participant.nationality')"
                :error-messages="formError(formErrorsProp, 'nationality')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.plain_ahv_number"
                :label="$t('participant.plain_ahv_number')"
                :error-messages="formError(formErrorsProp, 'plainAhvNumber')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-select
                v-model="data.german_knowledge"
                :items="germanKnowledges"
                :label="$t('participant.german_knowledge')"
                :error-messages="formError(formErrorsProp, 'germanKnowledge')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.xing"
                :label="$t('participant.xing')"
                :error-messages="formError(formErrorsProp, 'xing')"
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.linkedin"
                :label="$t('participant.linkedin')"
                :error-messages="formError(formErrorsProp, 'linkedin')"
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.website"
                :label="$t('participant.website')"
                :error-messages="formError(formErrorsProp, 'website')"
            />
        </v-col>
        <v-col cols="12">
            <span class="text-h6">{{ $t('participant.payer') }}</span>
            <v-radio-group
                v-model="data.rav"
                row
            >
                <v-radio
                    :label="$t('participant.rav')"
                    :value="1"
                />
                <v-radio
                    :label="$t('participant.not_rav')"
                    :value="0"
                />
                <v-radio
                    :label="$t('participant.rav_amm')"
                    :value="2"
                />
                <v-radio
                    :label="$t('participant.social_service')"
                    :value="3"
                />
            </v-radio-group>
        </v-col>
        <v-col
            v-if="data.rav === 1"
            cols="12"
        >
            <v-autocomplete
                v-model="data.rav_location.id"
                :items="ravLocations"
                :label="$t('participant.rav_location')"
                :error-messages="formError(formErrorsProp, 'ravLocation')"
                clearable
                @click:clear="$nextTick(() => data.rav_location = {})"
            />
        </v-col>
        <v-col
            cols="12"
            md="12"
            v-if="data.rav === 1 && data.rav_location.id === 'other'"
        >
            <v-text-field
                type="text"
                v-model="data.rav_location_freetext"
                :label="$t('participant.rav_location_freetext')"
                :error-messages="formError(formErrorsProp, 'ravLocationFreetext')"
            />
        </v-col>
        <v-col
            v-if="data.rav === 1"
            cols="12"
        >
            <v-text-field
                type="text"
                v-model="data.rav_consultant_email_freetext"
                :label="$t('participant.rav_consultant_email_freetext')"
                :error-messages="formError(formErrorsProp, 'ravConsultantEmailFreetext')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col
            v-if="data.rav === 1"
            cols="12"
        >
            <v-autocomplete
                v-model="data.insurance_fund.id"
                :items="insuranceFunds"
                :label="$t('participant.insurance_fund')"
                :error-messages="formError(formErrorsProp, 'insuranceFund')"
                clearable
                @click:clear="$nextTick(() => data.insurance_fund = {})"
            />
        </v-col>
        <v-expand-transition>
            <v-col
                cols="12"
                v-if="data.rav === 1 && data.insurance_fund.id === 'other'"
            >
                <v-text-field
                    type="text"
                    v-model="data.insurance_fund_freetext"
                    :label="$t('participant.insurance_fund_freetext')"
                    :error-messages="formError(formErrorsProp, 'insuranceFundFreetext')"
                />
            </v-col>
        </v-expand-transition>
        <v-col
            v-if="data.rav === 1"
            cols="12"
        >
            <v-text-field
                type="text"
                v-model="data.daily_allowances"
                :label="$t('first_interview.daily_allowances')"
                :error-messages="formError(formErrorsProp, 'dailyAllowances')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col
            v-if="data.rav === 2"
            cols="12"
        >
            <v-autocomplete
                v-model="data.amm.id"
                :items="amms"
                :label="$t('participant.amm')"
                :error-messages="formError(formErrorsProp, 'amm')"
                clearable
                @click:clear="$nextTick(() => data.amm = {})"
            />
        </v-col>
        <v-col
            cols="12"
            md="12"
            v-if="data.rav === 2 && data.amm.id === 'other'"
        >
            <v-text-field
                type="text"
                v-model="data.amm_freetext"
                :label="$t('participant.amm_freetext')"
                :error-messages="formError(formErrorsProp, 'ammFreetext')"
            />
        </v-col>
        <v-col
            v-if="data.rav === 3"
            cols="12"
        >
            <v-autocomplete
                v-model="data.social_service.id"
                :items="socialServices"
                :label="$t('participant.social_service')"
                :error-messages="formError(formErrorsProp, 'socialService')"
                clearable
                @click:clear="$nextTick(() => data.social_service = {})"
            />
        </v-col>
        <v-col
            cols="12"
            md="12"
            v-if="data.rav === 3 && data.social_service.id === 'other'"
        >
            <v-text-field
                type="text"
                v-model="data.social_service_freetext"
                :label="$t('participant.social_service_freetext')"
                :error-messages="formError(formErrorsProp, 'socialServiceFreetext')"
            />
        </v-col>
        <v-col cols="12">
            <v-textarea
                type="text"
                v-model="data.note"
                :label="$t('participant.note')"
                :error-messages="formError(formErrorsProp, 'note')"
            />
        </v-col>
    </v-row>
</template>

<script>

import BirthdatePicker from '@/components/common/BirthdatePicker';
import FormDataMixin from '@/mixins/form-data';

export default {
    name: 'StandardForm',
    mixins: [
        FormDataMixin,
    ],
    components: {
        BirthdatePicker,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        formErrorsProp: {
            type: Object,
            default: () => ({}),
        },
        cantons: {
            type: Array,
            default: () => ([]),
        },
        ravLocations: {
            type: Array,
            default: () => ([]),
        },
        insuranceFunds: {
            type: Array,
            default: () => ([]),
        },
        amms: {
            type: Array,
            default: () => ([]),
        },
        socialServices: {
            type: Array,
            default: () => ([]),
        },
        registrationTimeSlots: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>
