import Proxy from './Proxy';

class CourseCalendarProxy extends Proxy {
    constructor(parameters = {}) {
        super('/course-calendar', parameters);
    }

    all(courseIds) {
        if (Array.isArray(courseIds) && courseIds.length > 0) {
            return this.submit('get', `/?courses=${courseIds.join(',')}`);
        }
        return this.submit('get', '/');
    }

    download(id) {
        return this.submit('get', `/course/${id}/download-calendar`, null, null, {}, 'blob');
    }
}

export default CourseCalendarProxy;
