<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.social_service.title')"
            button
            :button-text="$t('page.social_service.new')"
            button-to="social_service_new"
            search
            :search-label="$t('page.social_service.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <ActionBar
                new-button
                new-to="social_service_new"
                :new-label="$t('page.social_service.new')"
                fetch
                :fetch-function="fetchData"
            />
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <OfficeList
                    v-if="!loading"
                    :objects="objects"
                    :search="textSearch"
                    :delete-callback="deleteObject"
                    :detail-route="'social_service_detail'"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import OfficeList from '@/components/office/Form/OfficeList.vue';
import SocialServiceProxy from '@/proxies/SocialServiceProxy';

import { SuccessSnackbar, ObjectDeleted } from '@/app-events.js';

export default {
    name: 'SocialServiceIndex',
    components: {
        ActionBar,
        PageHeader,
        OfficeList,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.social_service.title'),
                    disabled: true,
                },
            ],
            textSearch: '',
            objects: [],
            loading: true,
        };
    },
    created () {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('social_service.actions.deleted')));
            for (let i = 0; i < this.objects.length; i++) {
                const office = this.objects[ i ];
                if (office.id === event.object.id) {
                    this.objects.splice(i, 1);
                    break;
                }
            }
        });
    },
    methods: {
        deleteObject(id) {
            return new SocialServiceProxy().delete(id);
        },
        fetchData() {
            this.loading = true;
            new SocialServiceProxy().all().then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
    },
};
</script>
