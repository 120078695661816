<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-select
                        :items="courseTypes"
                        v-model="data.type"
                        :label="$t('course.type')"
                        :error-messages="formError(errors, 'type')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-select
                        :items="courseLanguages"
                        v-model="data.language"
                        :label="$t('course.language')"
                        :error-messages="formError(errors, 'language')"
                        :hint="$t('general.required')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-text-field
                        type="text"
                        v-model="data.name"
                        :label="$t('course.name')"
                        :error-messages="formError(errors, 'name')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-text-field
                        type="text"
                        v-model="data.execution_number"
                        :label="$t('course.execution_number')"
                        :error-messages="formError(errors, 'executionNumber')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <DatePicker
                        v-model="data.start_date"
                        :label="$t('course.start_date')"
                        :errors="errors"
                        error-key="startDate"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <DatePicker
                        v-model="data.end_date"
                        :label="$t('course.end_date')"
                        :errors="errors"
                        error-key="endDate"
                    />
                </v-col>
                <v-col
                    cols="12"
                    v-if="data.id"
                >
                    <v-icon style="color:orange; font-size: 1.3em;">mdi-alert</v-icon>
                    {{ $t('course.hint_edit_recreate') }}
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-checkbox
                        v-model="data.fully_booked"
                        :label="$t('course.fully_booked')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import DatePicker from '@/components/common/DatePicker';

export default {
    name: 'CourseInformation',
    components: {
        DatePicker
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            courseTypes: [
                {
                    value: 'OL',
                    text: `Kursart ${this.$t('course.types.OL')}`,
                },
                {
                    value: 'PS_SOCIAL',
                    text: `Kursart ${this.$t('course.types.PS_SOCIAL')}`,
                },
                {
                    value: 'PS_STORY',
                    text: `Kursart ${this.$t('course.types.PS_STORY')}`,
                },
                {
                    value: 'PS_VIDEO',
                    text: `Kursart ${this.$t('course.types.PS_VIDEO')}`,
                },
                {
                    value: 'APPLICATION_AGENCY',
                    text: `Kursart ${this.$t('course.types.APPLICATION_AGENCY')}`,
                },
                {
                    value: 'EASY',
                    text: `Kursart ${this.$t('course.types.EASY')}`,
                },
            ],
            courseLanguages: [
                {
                    value: 'de',
                    text: 'Deutsch',
                },
                {
                    value: 'en',
                    text: 'Englisch',
                },
            ]
        }
    },
};
</script>
