<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.firstname"
                        :label="$t('office.firstname')"
                        :error-messages="formError(errors, 'firstname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.lastname"
                        :label="$t('office.lastname')"
                        :error-messages="formError(errors, 'lastname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.phone"
                        :label="$t('office.phone')"
                        :error-messages="formError(errors, 'phone')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.email"
                        :label="$t('office.email')"
                        :error-messages="formError(errors, 'email')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'ContactPersonInformation',
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
