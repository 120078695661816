export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            if (data.week === null) {
                data.week = {};
            }

            return data;
        },
        prepareFormData(data, course) {
            return {
                course_calendar: {
                    course_name: data.title,
                    professor: data.professor,
                    type: data.type,
                    start: data.start_date + ' ' + data.timestart,
                    end: data.start_date + ' ' + data.timeend,
                    course: course.id,
                    color: course.color,
                    // same as backend CourseCalendarEntriesCreator
                    details: 'Dozent: ' + (data.professor || '(nicht definiert)') + ', Terminbezeichnung: ' + data.title,
                    name: data.title,
                },
            };
        },
    },
};
