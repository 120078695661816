var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.objects),function(weekValue,weekKey){return _c('v-row',{key:weekKey,staticClass:"mb-6",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10","offset-lg":"1"}},[_vm._v(" "+_vm._s(_vm.$t('calendar.week'))+" "+_vm._s(weekValue.week_no)+" – "+_vm._s(weekValue['name'])+" "),_c('v-btn',{staticClass:"mx-1 px-1",attrs:{"plain":""},on:{"click":function($event){return _vm.startEditObject(weekValue)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-1 px-1",attrs:{"plain":""},on:{"click":function($event){return _vm.initiateWeekDeletion(weekValue)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1),_vm._l((_vm.daysArr),function(dayValue,dayKey){return _c('v-col',{key:dayKey,staticClass:"d-flex",attrs:{"lg":"2","cols":"12","offset-lg":dayValue === 1 ? 1 : 0}},[_c('v-card',{staticClass:"mx-auto flex-grow-1 pb-3",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"space-between"}},[_c('span',{staticClass:"text-body-1 font-weight-bold",domProps:{"textContent":_vm._s(("" + (_vm.$t(("general.day_of_week." + dayValue)))))}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'calendar_new', params: { weekDay: ((weekValue.id) + "-" + dayValue) }},"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('page.calendar.new'))}})])],1),_c('hr',{staticClass:"my-3"}),((weekValue.calendar_static_entries).length > 0)?_c('div',_vm._l((_vm.computedWeekDay[weekValue.week_no][dayValue]),function(entry,ckey){return _c('div',{key:ckey,style:('background-color: '+_vm.$t('calendar.event_type.'+entry.type+'.color'))},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding-left":"4px"},attrs:{"cols":"2","align-self":"center"}},[_c('v-icon',[_vm._v("mdi-"+_vm._s(_vm.$t('calendar.event_type.'+entry.type+'.icon')))])],1),_c('v-col',[_c('v-list-item-subtitle',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(entry.start)+" - "+_vm._s(entry.end)+" "),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"float-right ml-1",attrs:{"small":"","color":"grey"},on:{"click":function($event){return _vm.initiateDeletion(entry)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.delete'))}})]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{staticClass:"calendar-link float-right",attrs:{"to":{ name: 'calendar_detail', params: { id: entry.id }}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.edit'))}})])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-black"},[_c('router-link',{staticClass:"calendar-link",attrs:{"to":{ name: 'calendar_detail', params: { id: entry.id }}}},[_vm._v(" "+_vm._s(entry.course)+" ")])],1),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(entry.professor)}})],1)],1)],1)}),0):_vm._e()],1)],1)],1)],1)})],2)}),_c('ConfirmationDialog',{attrs:{"confirm":_vm.deleteObject,"cancel":function () { return (_vm.deleteDialog = false); },"loading":_vm.deleting,"dialog":_vm.deleteDialog}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.weekname)+" ")]),_c('v-card-subtitle',{domProps:{"textContent":_vm._s("weekname")}}),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":_vm.nameLabel,"rules":_vm.rules,"error-messages":_vm.formError(_vm.formErrors, 'course_name'),"hint":_vm.$t('general.required')},model:{value:(_vm.weekname),callback:function ($$v) {_vm.weekname=$$v},expression:"weekname"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"default","text":""},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.updateWeekObject(_vm.week)}}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }