import DefaultLayout from '@/layouts/Default';
import WeekIndex from '@/views/Week/Index';
import WeekIDetail from '@/views/Week/Detail';
import WeekINew from '@/views/Week/New';

export default {
    path: '/week',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'week',
            component: WeekIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'week_new',
            component: WeekINew,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new/:courseType?/:language?',
            name: 'week_calendar',
            component: WeekINew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'week_detail',
            component: WeekIDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
