import Proxy from './Proxy';

class FirstInterviewProxy extends Proxy {
    constructor(parameters = {}) {
        super('/first-interview', parameters);
    }

    new(formData) {
        return this.submit('post', `/new`, formData);
    }

    courses(language) {
        return this.submit('get', `/courses?language=${language}`);
    }

    registrationTimeSlots() {
        return this.submit('get', '/registration-time-slots');
    }

    registrationTimeSlotsForCourse(course_id) {
        return this.submit('get', '/registration-time-slots?course_id='+course_id);
    }
}

export default FirstInterviewProxy;
