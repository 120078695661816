<template>
    <div>
        <v-row>
            <ActionBar
                fetch
                :fetch-function="fetchData"
            >
                <template #addons>
                    <v-menu
                        v-if="selectedRows.length > 0"
                        offset-y
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn
                                class="ml-3"
                                small
                                rounded
                                depressed
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ selectedRows.length }} {{ $t('general.selected') }}
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="openCertificateDialog('open')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_certificates')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="openCertificateDialog('send')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_certificates_send')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="false"
                                @click="emailContract('send')"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_learn_contract_send')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="generateCourseIdentityDocument('open')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.course_identity')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="generateCourseIdentityDocument('send')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_course_identity_send')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="generateMultipleFinalReports('send')">
                                <v-list-item-action>
                                    <v-icon>mdi-bullseye-arrow</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.final_report')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="false">
                                <v-list-item-action>
                                    <v-icon>mdi-pencil-box-multiple</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.bulk_edit')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="false">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-dialog
                        v-model="certificateDialog"
                        persistent
                        max-width="350px"
                    >
                        <v-card>
                            <v-card-title>
                                {{ $t('participant.downloads.amm_certificate') }}
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-date-picker
                                                locale="de-ch"
                                                v-model="certificateMonth"
                                                type="month"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-checkbox
                                                v-model="certificateCorrection"
                                                :label="$t('participant.downloads.amm_certificate_correction')"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn
                                    color="default"
                                    text
                                    @click="certificateDialog = false"
                                >
                                    {{ $t('general.cancel') }}
                                </v-btn>
                                <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="downloadCertificates()"
                                >
                                    {{ $t('general.export') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-tooltip
                        v-if="selectedRows.length > 0"
                        top
                    >
                        <template #activator="{ on }">
                            <v-btn
                                icon
                                :href="`mailto:?bcc=${selectedParticipantsEmails()}`"
                                v-on="on"
                                class="ml-3"
                            >
                                <v-icon>
                                    mdi-email-multiple-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>E-Mail an Teilnehmer</span>
                    </v-tooltip>
                </template>
            </ActionBar>
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <ParticipantsList
                    v-if="!loading"
                    :objects="participants"
                    :menu="waiting ? 'WL' : 'A'"
                    :show-status="showStatus"
                    :course-id="course.id"
                    selection
                    @selection-changed="selectionChangedListener"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import ParticipantsList from '@/components/participants/ParticipantsList';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import { ObjectDeleted, SuccessSnackbar, StandBy } from '@/app-events.js';
import FileDownloadMixin from '@/mixins/file-download';

export default {
    name: 'Participants',
    components: {
        ActionBar,
        ParticipantsList,
    },
    mixins: [ FileDownloadMixin ],

    props: {
        course: {
            type: Object,
            default: () => ({}),
        },
        waiting: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            participants: [],
            loading: true,
            selectedRows: [],
            certificateAction: null,
            certificateDialog: false,
            certificateCorrection: false,
            certificateMonth: new Date().toISOString().substr(0, 7),
        }
    },
    created() {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.deleted')));
            for (let i = 0; i < this.participants.length; i++) {
                const participant = this.participants[ i ];
                if (participant.id === event.object.id) {
                    this.participants.splice(i, 1);
                    break;
                }
            }
        });
    },
    methods: {
        fetchData() {
            new ParticipantProxy().all({
                courseId: this.course.id,
                waiting: this.waiting,
                active: this.active,
            }).then(({ data }) => {
                this.participants = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        selectionChangedListener(value) {
            this.selectedRows = value;
        },
        selectedParticipantsEmails(){
            const emails = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                if (item.email) {
                    emails.push(item.email);
                }
            }
            return emails.join(',');
        },
        openCertificateDialog(action) {
            this.certificateAction = action;
            this.certificateDialog = true;
        },
        /*emailContract(action) {
          this.$eventBus.dispatch(new StandBy(true));

          new ParticipantUploadProxy().emailLearnContract(item.id, item, documentType).then(({ data }) => {
              this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
          }).catch(({ data }) => {
              this.displayError(data);
          }).finally(() => {
              this.$eventBus.dispatch(new StandBy(false));
          });

      },*/
        generateCourseIdentityDocument(action) {
            this.$eventBus.dispatch(new StandBy(true));
            const ids = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                ids.push(item.id);
            }

            new ParticipantProxy().generateCourseIdentityDocuments(ids, action).then(({ data }) => {
                this.browserDownload(data, 'archive.zip');
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.downloads.generated.course_identity')));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        generateMultipleFinalReports() {
            this.$eventBus.dispatch(new StandBy(true));
            const ids = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                ids.push(item.id);
            }
            new ParticipantProxy().generateMultipleFinalReports(ids).then(({ data }) => {
                // this.browserDownload(data, 'archive.zip');
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.downloads.generated.goal')));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        downloadCertificates() {
            this.$eventBus.dispatch(new StandBy(true));
            const ids = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                ids.push(item.id);
            }
            if (this.certificateAction === 'open') {
                new ParticipantProxy().downloadCertificate(ids, this.certificateMonth, this.certificateCorrection, this.course.language).then(({ data }) => {
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.downloads.generated.amm_certificate')));
                    this.certificateDialog = false;
                    this.certificateAction = null;
                }).catch(({ data }) => {
                    this.displayError(data);
                }).finally(() => {
                    this.$eventBus.dispatch(new StandBy(false));
                });
            } else {
                new ParticipantProxy().sendCertificate(ids, this.certificateMonth).then(({ data }) => {
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.certificate_sent')));
                    this.certificateDialog = false;
                    this.certificateAction = null;
                }).catch(({ data }) => {
                    this.displayError(data);
                }).finally(() => {
                    this.$eventBus.dispatch(new StandBy(false));
                });
            }
        },
    },
};
</script>
