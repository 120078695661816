export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            if (data.week === null) {
                data.week = {};
            }

            return data;
        },
        prepareFormData(data) {
            return {
                calendar_static_entry: {
                    course: data.course,
                    professor: data.professor,
                    type: data.type,
                    start: data.start,
                    end: data.end,
                    week: data.week,
                    day: data.day,
                },
            };
        },
    },
};
