<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.calendar.title')"
            button
            :button-text="$t('page.calendar.new')"
            button-to="calendar_new"
            search
            :search-label="$t('page.calendar.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <ActionBar
                new-button
                new-to="calendar_new"
                :new-label="$t('page.calendar.new')"
                fetch
                :fetch-function="fetchData"
            >
                <template v-slot:addons>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                small
                                icon
                                v-clipboard:copy="syncUrl"
                                v-clipboard:success="onCopy"
                            >
                                <v-icon>mdi-calendar-sync</v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('calendar.sync_calendar_type')" />
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                small
                                icon
                                @click="exportDialog = true"
                                class="ml-3"
                            >
                                <v-icon>mdi-calendar-export</v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('page.calendar.export')" />
                    </v-tooltip>
                </template>
                <template v-slot:extra>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                small
                                icon
                                :to="{ name: 'week_calendar', params: { courseType: courseType, language: language }}"
                                class="ml-3"
                            >
                                <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('page.weekname.new')" />
                    </v-tooltip>
                </template>
            </ActionBar>
            <v-col cols="12">
                <v-row dense>
                    <v-col
                        cols="10"
                        offset-lg="1"
                    >
                        <v-progress-linear
                            v-if="loading"
                            indeterminate
                            color="primary"
                        />
                    </v-col>
                    <v-col
                        cols="2"
                        offset-lg="1"
                    >
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                </v-row>
                <CalendarList
                    v-if="!loading"
                    :objects="filteredObjects()"
                    :search="textSearch"
                    @selection-changed="selectionChangedListener"
                />
            </v-col>
        </v-row>

        <v-dialog
            v-model="exportDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title>
                    {{ $t('page.calendar.export') }}
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <DatePicker
                                    v-model="exportDate"
                                    :label="$t('page.calendar.start_day')"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="exportDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="exportCalendar()"
                    >
                        {{ $t('general.export') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import DatePicker from '@/components/common/DatePicker';
import CalendarList from '@/components/calendar/CalendarList';
import CalendarProxy from '@/proxies/CalendarProxy';
import WeekProxy from '@/proxies/WeekProxy';
import DatesMixin from '@/mixins/dates';
import FileDownloadMixin from '@/mixins/file-download';
import { SuccessSnackbar, ObjectDeleted, StandBy } from '@/app-events.js';

export default {
    name: 'CalendarIndex',
    mixins: [
        DatesMixin,
        FileDownloadMixin,
    ],
    components: {
        //MobileNewButton,
        ActionBar,
        PageHeader,
        DatePicker,
        CalendarList,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.calendar.title'),
                    disabled: true,
                },
            ],
            courseType: 'test',
            language: 'de',
            textSearch: '',
            objects: [],
            selectedRows: [],
            syncUrl: `${process.env.VUE_APP_API_ROOT}calendar-sync/course-type/${this.$route.params.courseType}.ics`,
            loading: true,
            exportDialog: false,
            exportDate: this.formatDateISO(this.beginingOfWeek(new Date())),
        };
    },
    created () {
        this.courseType = this.$route.params.courseType;
        this.language = this.$route.params.language;
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('calendar.actions.deleted')));
            this.fetchData();
        });
    },
    methods: {
        onCopy(e) {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('calendar.copied') + " (" + e.text + ")"));
        },
        filteredObjects() {
            if (!this.textSearch) {
                return this.objects;
            }
            const lowerCaseSearch = this.textSearch.toLowerCase();
            const filteredObjects = {};

            for (let i = 0; i < this.objects.length; i++) {
                for (let i1 = 0; i1 < this.objects[ i ][ 'calendar_static_entries' ].length; i1++) {
                    const calendar = this.objects[ i ][ 'calendar_static_entries' ][ i1 ];
                    if (calendar.course.toLowerCase().includes(lowerCaseSearch) || (calendar.professor && calendar.professor.toLowerCase().includes(lowerCaseSearch))) {
                        if ( !filteredObjects.hasOwnProperty(i) )
                        {
                            filteredObjects[ i ] = JSON.parse(JSON.stringify(this.objects[ i ]));

                            filteredObjects[ i ][ 'calendar_static_entries' ] = [];
                        }
                        filteredObjects[ i ][ 'calendar_static_entries' ].push(calendar);
                    }
                }
            }
            return Object.values(filteredObjects);
        },
        exportCalendar() {
            this.$eventBus.dispatch(new StandBy(true));
            new CalendarProxy().export(this.exportDate, this.courseType, this.language).then(({ data }) => {
                this.loading = false;
                this.exportDialog = false;
                this.browserDownload(data, 'calendar.ics');
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        fetchData() {
            this.loading = true;
            this.selectedRows = [];
            new WeekProxy().all({
                courseType: this.courseType === 'all' ? '' : this.courseType,
                language: this.language,
            }).then(({ data }) => {
                this.objects = data.data;
                this.calendarData = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
        selectionChangedListener(value) {
            this.selectedRows = value;
        },
    },
    watch: {
        '$route.params.courseType': function (val) {
            this.courseType = this.$route.params.courseType;
            this.fetchData();
        },
        '$route.params.language': function (val) {
            this.language = this.$route.params.language;
            this.fetchData();
        },
    },
};
</script>
