import Proxy from './Proxy';

class AssetsProxy extends Proxy {
    constructor(parameters = {}) {
        super('/assets', parameters);
    }

    downloadCorrespondenceDocument(id) {
        return this.submit('get', `/public/participant-correspondence/${id}/1`, null, null, {}, 'blob');
    }

    downloadUploadedDocument(id) {
        return this.submit('get', `/public/participant-upload/${id}/1`, null, null, {}, 'blob');
    }
}

export default AssetsProxy;
