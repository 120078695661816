import LoginLayout from '@/layouts/Login';
import Index from '@/views/FirstInterview/Index';
import FirstInterviewThankYou from '@/views/FirstInterview/ThankYou';

export default {
    path: '/kursanmeldung',
    component: LoginLayout,
    children: [
        {
            path: '',
            name: 'first_interview',
            component: Index,
            meta: {
                auth: false,
            },
        },
    ],
};
