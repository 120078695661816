<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="objects"
                :search="search"
                :show-select="selection"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('office_labor.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('office_labor.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
                group-by="canton.name"
            >
                <template v-slot:group.header="{items, isOpen, toggle}">
                    <th colspan="5">
                        <v-icon
                            @click="toggle"
                        >
                            {{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                        </v-icon>
                        {{ items[0].canton.name }} ({{ items[0].canton.code }})
                    </th>
                </template>
                <template v-slot:item.office_name="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: detailRoute, params: { id: item.id }}"
                    >
                        {{ item.office_name }}
                    </v-btn>
                </template>
                <template v-slot:item.email="{ item }">
                    <v-tooltip
                        v-if="item.email"
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                class="mx-1"
                                @click="copyText(item.email)"
                            >
                                <v-icon>
                                    mdi-content-copy
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.copy')" />
                    </v-tooltip>
                    {{ item.email }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                :href="`mailto:${item.email}`"
                                v-on="on"
                            >
                                <v-icon>
                                    mdi-email-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('office.send_email')" />
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                                class="mx-1"
                                :to="{ name: detailRoute, params: { id: item.id }}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.edit')" />
                    </v-tooltip>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="initiateDeletion(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
    </div>
</template>

<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import { ObjectDeleted, StandBy } from '@/app-events.js';

export default {
    name: 'OfficeList',
    components: {
        ConfirmationDialog,
    },
    props: {
        deleteCallback: {
            type: Function,
            default: (id) => (console.log('TODO: overwrite delete callback for '+id)),
        },
        detailRoute: {
            type: String,
            default: '',
        },
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            selectedRows: [],
            headers: [
                {
                    text: this.$t('office.office_name'),
                    value: 'office_name',
                    align: 'start',
                },
                {
                    text: this.$t('office.contact_person'),
                    value: 'contact_person',
                },
                {
                    text: this.$t('office.email'),
                    value: 'email',
                },
                {
                    text: this.$t('office.phone'),
                    value: 'phone',
                },
                {
                    value: 'canton.name',
                    align: ' d-none',
                },
                {
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'right',
                },
            ],
        }
    },
    methods: {
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            this.deleteCallback(this.objectToDelete.id).then(() => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        }
    },
};
</script>
