<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.weekname.title')"
            back
        />
        <v-row>
            <v-col cols="12">
                <WeekForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import WeekForm from '@/components/week/Form/WeekForm';
import { StandBy, SuccessSnackbar } from '@/app-events.js';
import WeekProxy from "@/proxies/WeekProxy";
import WeekMixin from '@/mixins/week';

export default {
    name: 'WeekNew',
    mixins: [ WeekMixin ],
    components: {
        PageHeader,
        WeekForm,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.weekname.title'),
                    exact: true,
                    to: { name: 'week' },
                },
                {
                    text: this.$t('general.new'),
                    disabled: true,
                },
            ],
            data: {},
            rawData: {},
        };
    },
    created() {
        if (typeof this.$route.params.courseType !== 'undefined') {
            const courseType = this.$route.params.courseType;
            this.data = {
                course_type: courseType.toUpperCase(),
                language: this.$route.params.language,
            };
            this.rawData = {
                course_type: courseType.toUpperCase(),
                language: this.$route.params.language,
            };
        }
    },
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            if (JSON.stringify(this.data) === JSON.stringify(this.rawData)) {
                return;
            }
            this.$eventBus.dispatch(new StandBy(true));
            new WeekProxy().new(this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('weekname.actions.created')));
                this.$router.push({ name: 'week_detail', params: { id: data.data.id } });
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
};
</script>
