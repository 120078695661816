<template>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        app
        clipped
        dark
        :mini-variant.sync="mini"
        expand-on-hover
        color="accent"
        class="d-print-none"
    >
        <v-list
            dense
            :subheader="true"
        >
            <v-list-item-group
                v-model="item"
                color="primary"
            >
                <v-list-item
                    :to="{ name: 'registrations' }"
                    router
                    value="registrations"
                >
                    <v-list-item-action>
                        <v-icon>mdi-account-plus-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Anmeldungen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="false">
                    <v-list-item-action>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Startseite</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-group
                    prepend-icon="mdi-account-multiple"
                    no-action
                    value="true"
                >
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>Teilnehmer</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item
                        :to="{ name: 'participants', params: { courseType: 'all' } }"
                        router
                        value="participants"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Alle</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'participants', params: { courseType: 'ol' } }"
                        router
                        value="participants"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.OL') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'participants', params: { courseType: 'ps_social' } }"
                        router
                        value="participants"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.PS_SOCIAL') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'participants', params: { courseType: 'ps_story' } }"
                        router
                        value="participants"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.PS_STORY') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'participants', params: { courseType: 'ps_video' } }"
                        router
                        value="participants"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.PS_VIDEO') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'participants', params: { courseType: 'application_agency' } }"
                        router
                        value="participants"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.APPLICATION_AGENCY') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <v-list-item
                    :to="{ name: 'course' }"
                    router
                    value="course"
                >
                    <v-list-item-action>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Kurse</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="false"
                    :to="{ name: 'learn_goal_control' }"
                    router
                    value="learn_goal_control"
                >
                    <v-list-item-action>
                        <v-icon>mdi-format-list-checks</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Lernzielkontrolle</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    :to="{ name: 'presence_list' }"
                    router
                    value="presenceList"
                >
                    <v-list-item-action>
                        <v-icon>mdi-playlist-check</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Präsenzliste</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                    prepend-icon="mdi-format-list-bulleted"
                    no-action
                    value="true"
                >
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>Listen</v-list-item-title>
                        </v-list-item-content>
                    </template>


                    <v-list-item
                        :to="{ name: 'rav' }"
                        router
                        value="rav"
                    >
                        <v-list-item-content>
                            <v-list-item-title>RAV</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'office_labor' }"
                        router
                        value="office_labor"
                    >
                        <v-list-item-content>
                            <v-list-item-title>AWA</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'social_service' }"
                        router
                        value="social_service"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Soziale Dienste</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'insurance_fund' }"
                        router
                        value="insurance_fund"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kassen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'amm' }"
                        router
                        value="amm"
                    >
                        <v-list-item-content>
                            <v-list-item-title>AMM</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    prepend-icon="mdi-email-outline"
                    no-action
                    value="true"
                >
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>Emails</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        :to="{ name: 'mailsent' }"
                        router
                        value="mailsent"
                    >
                        <v-list-item-content>
                            <v-list-item-title>E-Mails verwalten</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-email-send-outline</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'email_master_template' }"
                        router
                        value="email_master_template"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Email Master Template</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-email-multiple-outline</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    prepend-icon="mdi-calendar"
                    no-action
                    value="true"
                >
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>Kalendar</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        :to="{ name: 'calendar_overview' }"
                        router
                        value="calendar_overview"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kalenderübersicht</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'calendar', params: { courseType: 'ol', language: 'de' } }"
                        router
                        value="calendar"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.OL') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'calendar', params: { courseType: 'ps_social', language: 'de' } }"
                        router
                        value="calendar"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.PS_SOCIAL') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'calendar', params: { courseType: 'ps_story', language: 'de' } }"
                        router
                        value="calendar"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.PS_STORY') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'calendar', params: { courseType: 'ps_video', language: 'de' } }"
                        router
                        value="calendar"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.PS_VIDEO') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'calendar', params: { courseType: 'application_agency', language: 'de' } }"
                        router
                        value="calendar"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.APPLICATION_AGENCY') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        :to="{ name: 'calendar', params: { courseType: 'application_agency', language: 'en' } }"
                        router
                        value="calendar"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Kursart {{ $t('course.types.APPLICATION_AGENCY') }} EN</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                    :to="{ name: 'registration_time_slot' }"
                    router
                    value="registration_time_slot"
                >
                    <v-list-item-action>
                        <v-icon>mdi-alarm-snooze</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Erstgespräch Zeitfenster</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="false">
                    <v-list-item-action>
                        <v-icon>mdi-download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Downloads / Exporte</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>


                <v-list-group
                    v-if="$auth.check('ROLE_ADMIN')"
                    prepend-icon="mdi-cog"
                    no-action
                    value="true"
                >
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        :to="{ name: 'admin_users' }"
                        router
                        value="admin_users"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Benutzer</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-shield-account</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'admin_statistics' }"
                        router
                        value="admin_statistics"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Statistiken</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'general_setting' }"
                        router
                        value="general_setting"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Einstellungen</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-cog</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'week' }"
                        router
                        value="week"
                    >
                        <v-list-item-content>
                            <v-list-item-title>Wochenname</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-calendar</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-group>

                <v-list-item :to="{ name: 'change_password' }">
                    <v-list-item-action>
                        <v-icon>mdi-lock</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Passwort ändern</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="{ name: 'logout' }">
                    <v-list-item-action>
                        <v-icon>mdi-exit-to-app</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Abmelden</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <div v-if="false">
                    <v-divider />
                    <v-subheader class="mt-12">
                        <span v-if="!mini">KÜRZLICHE ANGSEHEN</span>
                    </v-subheader>

                    <v-list-item class="my-n2">
                        <v-list-item-action>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-light">
                                Hanna Muster
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="my-n2">
                        <v-list-item-action>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-light">
                                Glen Mustermann
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="my-n2">
                        <v-list-item-action>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-light">
                                Cornelia Sample
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="my-n2">
                        <v-list-item-action>
                            <v-icon>mdi-format-list-bulleted</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-light">
                                Jahresbericht
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'NavigationDrawer',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            item: '',
            mini: true,
            drawer: true,
        }
    },
    watch: {
        value(value) {
            this.drawer = value;
            this.$emit('input', value);
        },
    },
};
</script>
