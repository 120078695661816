<template>
    <v-row class="fill-height">
        <v-col>
            <v-sheet height="64">
                <v-toolbar
                    flat
                >
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        v-clipboard:copy="syncUrl"
                        v-clipboard:success="onCopy"
                    >
                        <v-icon>mdi-calendar-sync</v-icon>
                    </v-btn>
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="startAddObject()"
                    >
                        <v-icon>mdi-plus</v-icon> New Event
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                    >
                        <v-icon small>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                    >
                        <v-icon small>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        class="mr-4"
                        @click="downloadCalendar"
                    >
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-menu
                        bottom
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>
                                    mdi-menu-down
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Day</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Week</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Month</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title>4 days</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :start="course.start_date"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    :weekdays="weekday"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                />
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                >
                    <v-card
                        color="grey lighten-4"
                        min-width="350px"
                        flat
                    >
                        <v-toolbar
                            :color="$t('calendar.event_type.'+selectedEvent.type+'.color')"
                            dark
                        >
                            <v-icon style="padding-right: 10px;">
                                mdi-{{ $t('calendar.event_type.'+selectedEvent.type+'.icon') }}
                            </v-icon>
                            <v-toolbar-title v-html="selectedEvent.title" />
                            <v-spacer />
                            <v-btn
                                @click="deleteObject(selectedEvent.id)"
                                icon
                                v-if="null !== selectedEvent.id && currentlyEditing !== selectedEvent.id"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <form v-if="null === selectedEvent.id || currentlyEditing !== selectedEvent.id">
                                {{ selectedEvent.details }}
                            </form>
                            <form v-else>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            type="text"
                                            v-model="selectedEvent.title"
                                            :label="$t('calendar.title')"
                                            :hint="$t('general.required')"
                                            :error-messages="formError(formErrors, 'title')"
                                            persistent-hint
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            type="text"
                                            v-model="selectedEvent.professor"
                                            :label="$t('calendar.professor')"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            :items="entryTypes"
                                            v-model="selectedEvent.type"
                                            :label="$t('calendar.type')"
                                            :error-messages="formError(errors, 'type')"
                                            :hint="$t('general.required')"
                                            persistent-hint
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <DatePicker
                                            v-model="selectedEvent.start_date"
                                            :label="$t('calendar.day')"
                                            :hint="$t('general.required')"
                                            :error-messages="formError(formErrors, 'date')"
                                            persistent-hint
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            :items="hours"
                                            v-model="selectedEvent.timestart"
                                            :label="$t('calendar.start')"
                                            :hint="$t('general.required')"
                                            :error-messages="formError(formErrors, 'start')"
                                            persistent-hint
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            :items="hours"
                                            v-model="selectedEvent.timeend"
                                            :label="$t('calendar.end')"
                                            :hint="$t('general.required')"
                                            :error-messages="formError(formErrors, 'end')"
                                            persistent-hint
                                        />
                                    </v-col>
                                </v-row>
                            </form>
                        </v-card-text>
                        <v-card-actions>
                            <!-- To filter out multi-day events, we check for all day because we don't support creating those.
                                 If we add those, we should copy the start and end into the selectedEvent and check
                                     selectedEvent.start.startsWith(selectedEvent.end.substring(0, 10))
                                 Because start and end are strings that contain both day and time.
                             -->
                            <v-btn
                                v-if="null !== selectedEvent.id && currentlyEditing !== selectedEvent.id && false === selectedEvent.all_day"
                                @click="editEvent(selectedEvent)"
                                icon
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                color="default"
                                @click="closeEditEvent()"
                            >
                                {{ $t('general.cancel') }}
                            </v-btn>
                            <v-btn
                                v-if="currentlyEditing === selectedEvent.id"
                                @click="updateEvent(selectedEvent)"
                                text
                                color="primary darken-1"
                            >
                                {{ $t('general.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
            <v-dialog
                v-model="addDialog"
                persistent
                max-width="800px"
            >
                <v-card>
                    <v-card-title />

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        type="text"
                                        v-model="courseEvent.title"
                                        :label="$t('calendar.title')"
                                        :error-messages="formError(formErrors, 'course_name')"
                                        :hint="$t('general.required')"
                                        persistent-hint
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        type="text"
                                        v-model="courseEvent.professor"
                                        :error-messages="formError(formErrors, 'professor')"
                                        :label="$t('calendar.professor')"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        :items="entryTypes"
                                        v-model="courseEvent.type"
                                        :label="$t('calendar.type')"
                                        :error-messages="formError(formErrors, 'type')"
                                        :hint="$t('general.required')"
                                        persistent-hint
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <DatePicker
                                        v-model="courseEvent.start_date"
                                        :label="$t('calendar.day')"
                                        :error-messages="formError(formErrors, 'start_date')"
                                        error-key="startDate"
                                        :rules="rules"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        :items="hours"
                                        v-model="courseEvent.timestart"
                                        :label="$t('calendar.start')"
                                        :hint="$t('general.required')"
                                        :error-messages="formError(formErrors, 'start')"
                                        persistent-hint
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        :items="hours"
                                        v-model="courseEvent.timeend"
                                        :label="$t('calendar.end')"
                                        :hint="$t('general.required')"
                                        :error-messages="formError(formErrors, 'end')"
                                        persistent-hint
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="default"
                            text
                            @click="resetCalendarObject()"
                        >
                            {{ $t('general.cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary darken-1"
                            text
                            @click="addCourseCalendarObject(courseEvent)"
                        >
                            {{ $t('general.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import CourseCalendarProxy from "@/proxies/CourseCalendarProxy";
import { ObjectDeleted, StandBy, SuccessSnackbar } from '@/app-events.js';
import CourseCalendarMixin from '@/mixins/courseCalendar';
import DatePicker from '@/components/common/DatePicker';
import FileDownloadMixin from '@/mixins/file-download';

export default {
    mixins: [
        CourseCalendarMixin,
        FileDownloadMixin,
    ],
    components: {
        DatePicker
    },
    props: {
        course: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            syncUrl: `${process.env.VUE_APP_API_ROOT}calendar-sync/course/${this.course.id}.ics`,
            calendar:[],
            rules: [
                value => !!value || 'Pflichtfeld.',
            ],
            courseEvent:[],
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            weekday: [ 1, 2, 3, 4, 5, 6, 0 ],
            selectedEvent: {},
            selectedEventRaw: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            dialog: false,
            name: null,
            details: null,
            start: null,
            calendarStart: null,
            end: null,
            color: 'yellow',
            currentlyEditing: null,
            addDialog: false,
            // copy-paste with CalendarInformation.vue
            entryTypes: [
                {
                    value: 'onsite',
                    text: this.$t('calendar.event_type.onsite.label'),
                },
                {
                    value: 'voluntary-onsite',
                    text: this.$t('calendar.event_type.voluntary-onsite.label'),
                },
                {
                    value: 'online',
                    text: this.$t('calendar.event_type.online.label'),
                },
                {
                    value: 'coaching',
                    text: this.$t('calendar.event_type.coaching.label'),
                },
                {
                    value: 'independent',
                    text: this.$t('calendar.event_type.independent.label'),
                },
                {
                    value: 'milestone',
                    text: this.$t('calendar.event_type.milestone.label'),
                },
                {
                    value: 'off',
                    text: this.$t('calendar.event_type.off.label'),
                },
                {
                    value: 'other',
                    text: this.$t('calendar.event_type.other.label'),
                }
            ],
            hours: [
                {
                    value: '08:00',
                    text: '08:00',
                },
                {
                    value: '08:15',
                    text: '08:15',
                },
                {
                    value: '08:30',
                    text: '08:30',
                },
                {
                    value: '08:45',
                    text: '08:45',
                },
                {
                    value: '09:00',
                    text: '09:00',
                },
                {
                    value: '09:15',
                    text: '09:15',
                },
                {
                    value: '09:30',
                    text: '09:30',
                },
                {
                    value: '09:45',
                    text: '09:45',
                },
                {
                    value: '10:00',
                    text: '10:00',
                },
                {
                    value: '10:15',
                    text: '10:15',
                },
                {
                    value: '10:30',
                    text: '10:30',
                },
                {
                    value: '10:45',
                    text: '10:45',
                },
                {
                    value: '11:00',
                    text: '11:00',
                },
                {
                    value: '11:15',
                    text: '11:15',
                },
                {
                    value: '11:30',
                    text: '11:30',
                },
                {
                    value: '11:45',
                    text: '11:45',
                },
                {
                    value: '12:00',
                    text: '12:00',
                },
                {
                    value: '12:15',
                    text: '12:15',
                },
                {
                    value: '12:30',
                    text: '12:30',
                },
                {
                    value: '12:45',
                    text: '12:45',
                },
                {
                    value: '13:00',
                    text: '13:00',
                },
                {
                    value: '13:15',
                    text: '13:15',
                },
                {
                    value: '13:30',
                    text: '13:30',
                },
                {
                    value: '13:45',
                    text: '13:45',
                },
                {
                    value: '14:00',
                    text: '14:00',
                },
                {
                    value: '14:15',
                    text: '14:15',
                },
                {
                    value: '14:30',
                    text: '14:30',
                },
                {
                    value: '14:45',
                    text: '14:45',
                },
                {
                    value: '15:00',
                    text: '15:00',
                },
                {
                    value: '15:15',
                    text: '15:15',
                },
                {
                    value: '15:30',
                    text: '15:30',
                },
                {
                    value: '15:45',
                    text: '15:45',
                },
                {
                    value: '16:00',
                    text: '16:00',
                },
                {
                    value: '16:15',
                    text: '16:15',
                },
                {
                    value: '16:30',
                    text: '16:30',
                },
                {
                    value: '16:45',
                    text: '16:45',
                },
                {
                    value: '17:00',
                    text: '17:00',
                },
                {
                    value: '17:15',
                    text: '17:15',
                },
                {
                    value: '17:30',
                    text: '17:30',
                },
                {
                    value: '17:45',
                    text: '17:45',
                },
                {
                    value: '18:00',
                    text: '18:00',
                },
                {
                    value: '18:15',
                    text: '18:15',
                },
                {
                    value: '18:30',
                    text: '18:30',
                },
                {
                    value: '18:45',
                    text: '18:45',
                },
                {
                    value: '19:00',
                    text: '19:00',
                },
            ],

        }
    },
    mounted () {
        this.fetchData();
    },
    methods: {
        closeEditEvent() {
            this.selectedOpen = false;
            this.currentlyEditing = null;
            this.selectedEvent = JSON.parse(JSON.stringify(this.selectedEventRaw))
        },
        fetchData() {
            new CourseCalendarProxy().all([ this.course.id ]).then(({ data }) => {
                this.events = data.data;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        setDialogDate( { date }) {
            this.dialogDate = true
            this.focus = date
        },
        updateEvent(object) {
            new CourseCalendarProxy().update(object.id,this.prepareFormData(object, this.course)).then(({ data }) => {
                this.selectedOpen = false;
                this.currentlyEditing = null;
                this.fetchData();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        deleteObject(objectID) {
            new CourseCalendarProxy().delete(objectID).then(({ data }) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.selectedOpen = false;
                this.fetchData();
            });

        },
        addCourseCalendarObject(object) {
            object.course = this.course;
            new CourseCalendarProxy().new(this.prepareFormData(object, this.course)).then(({ data }) => {
                this.addDialog = false;
                object.title = '';
                object.professor = '';
                object.start_date = '';
                object.timestart = '';
                object.timeend = '';
                this.fetchData();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        resetCalendarObject() {
            this.courseEvent.title = '';
            this.courseEvent.professor = '';
            this.courseEvent.start_date = '';
            this.courseEvent.timestart = '';
            this.courseEvent.timeend = '';
            this.addDialog = false;
            this.selectedOpen = false;
            this.currentlyEditing = null;
            this.formErrors = {};
        },
        startAddObject() {
            this.resetCalendarObject();
            this.addDialog = true;
        },
        onCopy(e) {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('calendar.copied') + " (" + e.text + ")"));
        },
        onCopyError(e) {
            this.displayError(e);
        },
        downloadCalendar() {
            this.$eventBus.dispatch(new StandBy(true));
            new CourseCalendarProxy().download(this.course.id).then(({ data }) => {
                this.browserDownload(data, 'Kalender.pdf');
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        exportCalendar() {
            this.$eventBus.dispatch(new StandBy(true));
            new CourseCalendarProxy().export(this.course.id).then(({ data }) => {
                this.browserDownload(data, 'calendar.ics');
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return this.$t('calendar.event_type.'+event.type+'.color');
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        editEvent (event) {
            this.currentlyEditing = event.id;
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                const eventRowStart = event.start.split(" ");
                const eventRowEnd = event.end.split(" ");
                this.selectedEvent = JSON.parse(JSON.stringify(event))
                this.selectedEvent.timestart = eventRowStart[ 1 ];
                this.selectedEvent.timeend = eventRowEnd[ 1 ];
                this.selectedEvent.start_date = eventRowEnd[ 0 ];
                this.selectedEvent.title = this.selectedEvent.title || this.selectedEvent.name;
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                this.selectedEventRaw = JSON.parse(JSON.stringify(this.selectedEvent))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        updateRange ({ start, end }) {
            this.start = start
            this.end = end
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
    },
}
</script>
