<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.admin.statistics.title')"
        />
        <v-row>
            <v-col cols="12">
                <v-card
                    max-width="400"
                    flat
                >
                    <v-list>
                        <v-list-item-group color="primary">
                            <v-list-item @click="rangeDateStats('exit_status')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.exit_status')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="yearStats('by_month')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.month')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="yearStats('by_canton')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.canton')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="yearStats('first_interview')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.first_interview')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="rangeDateStats('waitlist')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.waitlist')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="rangeDateStats('integration_rate')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.integration_rate')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="rangeDateStats('dropout_rate')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.dropout_rate')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="rangeDateStats('workload')">
                                <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.admin.statistics.workload')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="rangeDateDialog"
            persistent
            max-width="700px"
        >
            <v-card>
                <v-card-title>
                    {{ $t('page.admin.statistics.exit_status') }}
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                sm="6"
                                cols="12"
                            >
                                <v-date-picker
                                    locale="de-ch"
                                    :first-day-of-week="1"
                                    v-model="startDate"
                                />
                            </v-col>
                            <v-col
                                sm="6"
                                cols="12"
                            >
                                <v-date-picker
                                    locale="de-ch"
                                    :first-day-of-week="1"
                                    v-model="endDate"
                                />
                            </v-col>
                            <v-col
                                sm="6"
                                cols="12"
                            >
                                <v-layout row wrap>
                                    <v-flex v-for="courseType in courseTypes" :key="courseType.value" xs6>
                                        <v-checkbox :label="courseType.text" :value="courseType.value" v-model="selectedCourseTypes" />
                                    </v-flex>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="rangeDateDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="showStat()"
                    >
                        {{ $t('general.export') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="yearDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-select
                                    v-model="year"
                                    :items="yearOptions()"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-layout row wrap>
                                    <v-flex v-for="courseType in courseTypes" :key="courseType.value" xs6>
                                        <v-checkbox :label="courseType.text" :value="courseType.value" v-model="selectedCourseTypes" />
                                    </v-flex>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="yearDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="showStat()"
                    >
                        {{ $t('general.export') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import StatisticsProxy from '@/proxies/StatisticsProxy';
import FileDownloadMixin from '@/mixins/file-download';

import { StandBy } from '@/app-events.js';

export default {
    name: 'StatisticsIndex',
    mixins: [
        FileDownloadMixin,
    ],
    components: {
        PageHeader,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.admin.title'),
                    disabled: true,
                    href: '/admin',
                },
                {
                    text: this.$t('page.admin.statistics.title'),
                    disabled: true,
                },
            ],
            rangeDateDialog: false,
            stat: null,
            monthDialog: false,
            yearDialog: false,
            startDate: `${new Date().toISOString().substr(0, 4)}-01-01`,
            endDate: new Date().toISOString().substr(0, 10),
            selectedCourseTypes: [],
            year: parseInt(new Date().toISOString().substr(0, 4)),
            courseTypes: [
                {
                    value: 'OL',
                    text: this.$t('course.types.OL'),
                },
                {
                    value: 'PS_SOCIAL',
                    text: this.$t('course.types.PS_SOCIAL'),
                },
                {
                    value: 'PS_STORY',
                    text: this.$t('course.types.PS_STORY'),
                },
                {
                    value: 'PS_VIDEO',
                    text: this.$t('course.types.PS_VIDEO'),
                },
                {
                    value: 'APPLICATION_AGENCY',
                    text: this.$t('course.types.APPLICATION_AGENCY'),
                },
                {
                    value: 'EASY',
                    text: this.$t('course.types.EASY'),
                },
            ],
        };
    },
    methods: {
        yearStats(stat) {
            this.stat = stat;
            this.yearDialog = true;
        },
        rangeDateStats(stat) {
            this.stat = stat;
            this.rangeDateDialog = true;
        },
        showStat() {
            if (this.stat === 'by_canton') {
                this.downloadCanton();
            } else if (this.stat === 'by_month') {
                this.downloadMonth();
            } else if (this.stat === 'first_interview') {
                this.downloadFirstInterview();
            } else if (this.stat === 'exit_status') {
                this.downloadExitStatus();
            } else if (this.stat === 'waitlist') {
                this.downloadWaitlist();
            } else if (this.stat === 'integration_rate') {
                this.downloadIntegrationRate();
            } else if (this.stat === 'dropout_rate') {
                this.downloadDropoutRate();
            } else if (this.stat === 'workload') {
                this.downloadWorkload();
            }
        },
        yearOptions() {
            const years = [];
            const start = 2010;
            const now = parseInt(new Date().toISOString().substr(0, 4));
            for (let i = now; i >= start; i--) {
                years.push(i);

            }
            return years;
        },
        downloadByRangeDate(name, filename) {
            this.$eventBus.dispatch(new StandBy(true));
            new StatisticsProxy().generate(name, this.startDate, this.endDate, this.selectedCourseTypes).then(({ data }) => {
                this.browserDownload(data, filename);
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.rangeDateDialog = false;
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        downloadExitStatus() {
            this.downloadByRangeDate('exit-status', 'Teilnehmer-Austrittsstatus.xlsx');
        },
        downloadWaitlist() {
            this.downloadByRangeDate('waitlist', 'Warteliste.xlsx');
        },
        downloadIntegrationRate() {
            this.downloadByRangeDate('integration', 'Eingliederungsquote.xlsx');
        },
        downloadDropoutRate() {
            this.downloadByRangeDate('dropout-rate', 'Abbruchsquote.xlsx');
        },
        downloadWorkload() {
            this.downloadByRangeDate('workload', 'Auslastung.xlsx');
        },
        downloadByMonth(name, filename) {
            this.$eventBus.dispatch(new StandBy(true));
            new StatisticsProxy().generateByYear(name, this.year, this.selectedCourseTypes).then(({ data }) => {
                this.browserDownload(data, filename);
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.yearDialog = false;
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        downloadMonth() {
            this.downloadByMonth('month', `Teilnehmer pro Monat ${this.year}.xlsx`);
        },
        downloadCanton() {
            this.downloadByMonth('canton', `Teilnehmer nach Kantonen ${this.year}.xlsx`);
        },
        downloadFirstInterview() {
            this.downloadByMonth('first-interview', `Erstgespräche pro Monat ${this.year}.xlsx`);
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
    },
};
</script>
