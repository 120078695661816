<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="objects"
                :search="search"
                :custom-filter="filterWeekList"
                :show-select="selection"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('week.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('week.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
            >
                <template v-slot:item.name="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'week_detail', params: { id: item.id }}"
                    >
                        {{ item.name }}
                    </v-btn>
                </template>

                <template v-slot:item.week_no="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'week_detail', params: { id: item.id }}"
                    >
                        {{ $t('weekname.week') }}&nbsp;{{ item.week_no }}
                    </v-btn>
                </template>
                <template v-slot:item.number="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'week_detail', params: { id: item.id }}"
                    >
                        {{ item.course_type }}
                    </v-btn>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                                class="mx-1"
                                :to="{ name: 'week_detail', params: { id: item.id }}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.edit')" />
                    </v-tooltip>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="initiateDeletion(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
    </div>
</template>

<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import { ObjectDeleted } from '@/app-events.js';
import WeekProxy from "@/proxies/WeekProxy";

export default {
    name: 'Week',
    components: {
        ConfirmationDialog,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            selectedRows: [],
            headers: [
                {
                    text: this.$t('weekname.name'),
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('weekname.week'),
                    value: 'week_no',
                },
                {
                    text: this.$t('weekname.course'),
                    value: 'course_type',
                },
                {
                    text: this.$t('weekname.language'),
                    value: 'language',
                },
                {
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'right',
                },
            ],
        }
    },
    methods: {
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new WeekProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
        filterWeekList(value, search, item) {
            if (search === '') {
                return true;
            }
            if (!value) {
                return false;
            }
            if (item.week_no === value)
            {
                value = 'Woche '+item.week_no;
            }
            return value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        },
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        }
    },
};
</script>
