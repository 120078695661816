import Proxy from './Proxy';

class CourseProxy extends Proxy {
    constructor(parameters = {}) {
        super('/course', parameters);
    }

    all(show) {
        if (show) {
            return this.submit('get', `/?show=${show}`);
        }
        return this.submit('get', '/');
    }
}

export default CourseProxy;
