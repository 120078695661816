import Proxy from './Proxy';

class CorrespondenceProxy extends Proxy {
    constructor(parameters = {}) {
        super('/participant-correspondence', parameters);
    }

    all({ participantId = null } = {}) {
        if (participantId) {
            return this.submit('get', `/?participant=${participantId}`);
        }
        return this.submit('get', '/');
    }
}

export default CorrespondenceProxy;
