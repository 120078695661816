import Proxy from './Proxy';

class StatisticsProxy extends Proxy {
    constructor(parameters = {}) {
        super('/admin/statistics', parameters);
    }

    generate(name, start, end, courseTypes) {
        return this.submit('get', `/${name}?start=${start}&end=${end}&course-types=${courseTypes.join(',')}`, null, null, {}, 'blob');
    }

    generateByYear(name, year, courseTypes) {
        return this.submit('get', `/${name}?year=${year}&course-types=${courseTypes.join(',')}`, null, null, {}, 'blob');
    }
}

export default StatisticsProxy;
