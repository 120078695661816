import Proxy from './Proxy';

class WeekProxy extends Proxy {
    constructor(parameters = {}) {
        super('/week', parameters);
    }

    all({ courseType = '', language = 'de' } = {}) {
        if (courseType === '') {
            return this.submit('get', `/?courseType=ALL`);
        }
        courseType = courseType.toUpperCase();

        return this.submit('get', `/?courseType=${courseType}&language=${language}`);
    }
}

export default WeekProxy;
